<template>
  <div id="table-logs" class="container-fluid">
    <div class="page-header mb-1">
      <strong>ประวัติการตั้งค่างวดหวย</strong>
    </div>
    <div>
      <table class="table table-hover table-sm table-bordered">
        <thead>
          <tr>
            <th class="text-center" width="200">วันที่</th>
            <th class="text-center" width="150">โดย</th>
            <th class="text-center">รายละเอียด</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item._id">
            <td class="text-center">{{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}</td>
            <td class="text-center">{{item.byUsername}}</td>
            <td>
              <LoggerDescription :type="item.logType" :description="item.descriptions" />
            </td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="3" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import RoundService from "@/services/RoundService"

import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

import LoggerDescription from '@/views/logger/components/LoggerDescription'

export default {
  name: 'SettingHistory',
  components: {
    LoggerDescription
  },
  data() {
    return {
      data: null
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    items() {
      if(!this.data)
        return []

      return this.data
    }
  },
  watch: {
    roundId() {
      this.getData()
    }
  },
  methods: {
    getData() {
      RoundService.getRoundSettingHistory(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'ดาวโหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
<style lang="scss">
#table-logs {
  .table {
    background-color: #FFF;
    font-size: 85%;

    tbody {
      tr {
        td {
          vertical-align: top;

          .badge {
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>
